import React from 'react';
import { Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Privacy Policy
      </Typography>
      
      <Typography variant="body1" paragraph>
        This Privacy Policy outlines the handling of users' personal information in the services provided (hereinafter referred to as "the Service"). By using the Service, users agree to this Privacy Policy.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        1. Information Collected
      </Typography>
      
      <Typography variant="body1" paragraph>
        The Service may collect the following information:
      </Typography>
      
      <Typography variant="body1" component="ul">
        <li><strong>Voice Data</strong>: Voice data recorded by users when using the Service. The recorded voice data is analyzed on our servers and deleted once the analysis is complete.</li>
        <li><strong>Google Analytics Data</strong>: Anonymous statistical information is collected using Google Analytics to understand the usage of the Service. This information does not include personally identifiable information.</li>
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        2. Purpose of Information Use
      </Typography>
      
      <Typography variant="body1" paragraph>
        The collected information will be used for the following purposes:
      </Typography>
      
      <Typography variant="body1" component="ul">
        <li>Analysis of voice data and provision of the Service</li>
        <li>Improvement of the Service and understanding of usage trends</li>
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        3. Sharing and Provision of Information
      </Typography>
      
      <Typography variant="body1" paragraph>
        The Service will not provide users' personal information to third parties. However, this does not apply in cases where required by law or when the user has given consent.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        4. Handling of Voice Data
      </Typography>
      
      <Typography variant="body1" paragraph>
        The recorded voice data will be deleted once the analysis is complete. The voice data is only temporarily stored and will not be kept long-term or provided to third parties.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        5. Use of Google Analytics
      </Typography>
      
      <Typography variant="body1" paragraph>
        The Service uses Google Analytics to analyze usage trends. The data collected by Google Analytics is anonymous statistical information and does not include personally identifiable information. For more details on Google Analytics' Privacy Policy, please refer to their website.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        6. Changes to the Privacy Policy
      </Typography>
      
      <Typography variant="body1" paragraph>
        This Privacy Policy may be updated as necessary. In the case of significant changes, users will be notified.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        7. Contact Information
      </Typography>
      
      <Typography variant="body1" paragraph>
        For any questions or inquiries regarding the Privacy Policy, please contact us at https://tayori.com/form/9c1e3414dc5746898896dfd2ccee455041ab4d79.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;