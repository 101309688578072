import React, { useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import { logEvent } from '../analytics';
import AccentVisualizer from './AccentVisualizer';

const ResultDisplay = ({ textData, userAccent, isCorrect }) => {
  useEffect(() => {
    logEvent('Result', isCorrect ? 'Perfect' : 'Try Again');
  }, [isCorrect]);
  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={{ mt: 0, display: 'flex', justifyContent: 'flex-start' }}>
        <Typography 
          variant="h6" 
          color={isCorrect ? 'success.main' : 'error.main'}
          sx={{ fontWeight: 'bold' }}
        >
          {isCorrect ? 'Perfect!' : 'Try Again'}
        </Typography>
      </Box>
    </Box>
  );
};

export default ResultDisplay;