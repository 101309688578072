import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { initGA, logPageView } from './analytics';
import Initial from './components/Initial';

function App() {
  useEffect(() => {
    initGA('G-L2QE5XXTZT'); // あなたの測定IDに置き換えてください
    logPageView();
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Initial />} />
      </Routes>
    </Router>
  );
}

export default App;