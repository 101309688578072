import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

const SearchResults = ({ results, onSelect }) => {
  const visibleResults = results.slice(0, 6); // 最大6つの結果を表示
  const hasMoreResults = results.length > 6;

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Grid container spacing={1}>
        {visibleResults.map((result) => (
          <Grid item xs={6} key={result.Id}>
            <Card 
              onClick={() => onSelect(result)}
              sx={{ 
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                height: '100%',
              }}
            >
              <CardContent>
                <Typography variant="h8" component="div">
                  {result.Kanji} / {result.Text} / {result.Alphabet} / {result.Meaning}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {hasMoreResults && (
        <Box sx={{ mt: 2, textAlign: 'center' }}>
          <Typography color="text.secondary">
            More...
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default SearchResults;