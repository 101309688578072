import React, { useState } from 'react';
import {
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid
} from '@mui/material';
import AccentVisualizer from './AccentVisualizer';

const HowItWorksDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>How It Works</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Japanese accents are expressed through pitch. If the pitch accent is different, it can not only give the impression of being non-native but also change the meaning. For example, the same sound "はし" can mean:
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              mb: 2, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}>
              <AccentVisualizer
                text="はし"
                accent="LH"
                isVisualizationEnabled={true}
              />
              <Typography variant="caption" sx={{ mt: 1 }}>はし (橋 / Bridge)</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              mb: 2, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'
            }}>
              <AccentVisualizer
                text="はし"
                accent="HL"
                isVisualizationEnabled={true}
              />
              <Typography variant="caption" sx={{ mt: 1 }}>はし (箸 / Chopsticks)</Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography>
          If a horizontal line is displayed above the character, read it with a high pitch; if a horizontal line is displayed below the character, read it with a low pitch. This will sound natural to Japanese natives and make communication smoother. In this app, if you read the displayed characters according to the displayed accents, the AI will analyze and determine if the accent is correct. Don't be embarrassed to try multiple times, practice repeatedly to master the correct pronunciation.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const HowItWorksBlock = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Typography 
        onClick={handleOpenDialog}
        sx={{ 
          mt: 2,
          textDecoration: 'underline',
          cursor: 'pointer',
          fontSize: '0.9rem',
          color: 'black',
          textAlign: 'center'
        }}
      >
        How It Works
      </Typography>
      <HowItWorksDialog open={isDialogOpen} onClose={handleCloseDialog} />
    </>
  );
};

export default HowItWorksBlock;