import React from 'react';
import { Box } from '@mui/material';

const AccentVisualizer = ({ text, accent, isVisualizationEnabled, userAccent }) => {
  const charWidth = 30;
  const charHeight = 40;
  const lineOffsetHigh = 5;
  const lineOffsetLow = 35;

  const isSmallKana = (char) => {
    return ['ゃ', 'ゅ', 'ょ', 'ぁ', 'ぃ', 'ぅ', 'ぇ', 'ぉ', 'っ'].includes(char);
  };

  const renderAccentedText = () => {
    const characters = text.split('');
    let currentX = 0;
    const correctPath = [];
    const userPath = [];
    let accentIndex = 0;
    let userAccentIndex = 0;
    let prevAccent = null;
    let prevUserAccent = null;

    const elements = characters.map((char, index) => {
      const width = charWidth;

      let currentAccent;
      let currentUserAccent;
      if (isSmallKana(char)) {
        currentAccent = prevAccent || 'L';
        currentUserAccent = prevUserAccent || 'L';
      } else {
        currentAccent = accent[accentIndex];
        currentUserAccent = userAccent ? userAccent[userAccentIndex] : 'L';
        accentIndex++;
        userAccentIndex++;
      }

      const isHigh = currentAccent === 'H';
      const isUserHigh = currentUserAccent === 'H';
      const lineY = isHigh ? lineOffsetHigh : lineOffsetLow;
      const userLineY = isUserHigh ? lineOffsetHigh : lineOffsetLow;

      // Correct accent path
      if (index === 0 || currentAccent !== prevAccent) {
        if (index > 0) {
          const prevLineY = prevAccent === 'H' ? lineOffsetHigh : lineOffsetLow;
          correctPath.push(`L${currentX} ${prevLineY}`);
          correctPath.push(`L${currentX} ${lineY}`);
        } else {
          correctPath.push(`M${currentX} ${lineY}`);
        }
      } else {
        correctPath.push(`L${currentX} ${lineY}`);
      }
      correctPath.push(`L${currentX + width} ${lineY}`);

      // User accent path
      if (userAccent) {
        if (index === 0 || currentUserAccent !== prevUserAccent) {
          if (index > 0) {
            const prevUserLineY = prevUserAccent === 'H' ? lineOffsetHigh : lineOffsetLow;
            userPath.push(`L${currentX} ${prevUserLineY}`);
            userPath.push(`L${currentX} ${userLineY}`);
          } else {
            userPath.push(`M${currentX} ${userLineY}`);
          }
        } else {
          userPath.push(`L${currentX} ${userLineY}`);
        }
        userPath.push(`L${currentX + width} ${userLineY}`);
      }

      const charElement = (
        <text 
          key={`char-${index}`} 
          x={currentX} 
          y={charHeight / 2} 
          fontSize="24"
          textAnchor="start" 
          dominantBaseline="central"
          className="text-only"
        >
          {char}
        </text>
      );

      currentX += width;
      prevAccent = currentAccent;
      prevUserAccent = currentUserAccent;

      return charElement;
    });

    return (
      <>
        {isVisualizationEnabled && (
          <>
            <path d={correctPath.join(' ')} fill="none" stroke="gray" strokeWidth="2" />
            {userAccent && (
              <path d={userPath.join(' ')} fill="none" stroke="#19e64c" strokeWidth="2" strokeOpacity="0.6" />
            )}
          </>
        )}
        {elements}
      </>
    );
  };

  return (
    <Box sx={{ textAlign: 'center', whiteSpace: 'nowrap', overflow: 'visible' }}>
      <svg 
        width={`${text.length * charWidth}px`}
        height={charHeight} 
        style={{ display: 'block', marginBottom: '2px', whiteSpace: 'nowrap' }}
      >
        {renderAccentedText()}
      </svg>
    </Box>
  );
};

export default AccentVisualizer;