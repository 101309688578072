import React, { useState, useEffect, useRef,useCallback } from 'react';
import axios from 'axios';
import { 
  IconButton, 
  Button, 
  Typography, 
  Switch, 
  Box, 
  CircularProgress,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Toolbar,
  Link,
  Container,
  Grid,
  TextField,
  InputAdornment,
  Select,  // // 新しいインポート
  MenuItem,  // // 新しいインポート
  FormControl,  // // 新しいインポート
  InputLabel  // // 新しいインポート
} from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';  // Add this line
import AccentVisualizer from './AccentVisualizer';
import ResultDisplay from './ResultDisplay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import { logEvent } from '../analytics';
import SearchBox from './SearchBox';
import SearchResults from './SearchResults';
import { debounce } from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import HowItWorksBlock from './HowItWorksBlock';  // 新しいインポート

const getSessionId = () => {
  if (!sessionStorage.getItem('sessionId')) {
    sessionStorage.setItem('sessionId', Date.now().toString(36) + Math.random().toString(36).substr(2));
  }
  return sessionStorage.getItem('sessionId');
};

const Initial = () => {
  const [textData, setTextData] = useState(null);
  const [accentVisualization, setAccentVisualization] = useState(true);
  const [currentId, setCurrentId] = useState(1);
  const [iconPosition, setIconPosition] = useState({ left: 'auto', top: 'auto' });
  const [isPlaying, setIsPlaying] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [recordingStatus, setRecordingStatus] = useState('idle');
  const [resultData, setResultData] = useState(null);
  const [userAccent, setUserAccent] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
  const [isPrivacyDialogOpen, setIsPrivacyDialogOpen] = useState(false);
  const chunks = useRef([]);
  const boxRef = useRef(null);
  const audioRef = useRef(new Audio());
  const [expanded, setExpanded] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);  // // 新しい状態変数
  const [selectedCategory, setSelectedCategory] = useState('');  // // 新しい状態変数
  const [isSynthesizing, setIsSynthesizing] = useState(false);
  const [isLastId, setIsLastId] = useState(false);

  const API_KEY = process.env.REACT_APP_API_KEY;
  const API_URL = process.env.REACT_APP_API_URL;

  const api = axios.create({
    baseURL: API_URL,
    headers: {
      'X-API-Key': API_KEY
    }
  });

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query.trim()) {
        setSearchResults([]);
        setIsSearching(false);
        return;
      }

      setIsSearching(true);
      try {
        const response = await api.get(`/search?query=${encodeURIComponent(query)}`);
        setSearchResults(response.data);
      } catch (error) {
        //console.error('Error searching:', error);
        setErrorMessage('Failed to perform search. Please try again.');
        setIsErrorDialogOpen(true);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    []
  );

  const resetRecordingStatus = () => {
    setRecordingStatus('idle');
    setIsRecording(false);
    setCountdown(5);
    setResultData(null);
    setUserAccent(null);
  };

  useEffect(() => {
    fetchTextById(currentId);
    resetRecordingStatus();
  }, [currentId]);

  useEffect(() => {
    if (textData) {
      setTimeout(calculateIconPosition, 0);
    }
  }, [textData, accentVisualization]);

  useEffect(() => {
    window.addEventListener('resize', calculateIconPosition);
    return () => window.removeEventListener('resize', calculateIconPosition);
  }, []);

  useEffect(() => {
    let timer;
    if (isRecording && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    } else if (countdown === 0) {
      stopRecording();
    }
    return () => clearInterval(timer);
  }, [isRecording, countdown]);

  useEffect(() => {
    return () => {
      if (recorder) {
        try {
          recorder.stopRecording();
          recorder.destroy();
        } catch (error) {
          //console.error('Error destroying recorder:', error);
        }
      }
    };
  }, [recorder]);

  useEffect(() => {
    //console.log('searchQuery:', searchQuery);
  }, [searchQuery]);
  
  useEffect(() => {
    //console.log('searchResults:', searchResults);
  }, [searchResults]);

  // // 新しいuseEffect: カテゴリを取得
  useEffect(() => {
    fetchCategories();
  }, []);

    // // 新しい関数: カテゴリを取得
    const fetchCategories = async () => {
      try {
        const response = await api.get('/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
  // カテゴリ選択ボックスをリセットする関数
    const resetCategorySelect = () => {
      const select = document.getElementById('category-select');
      if (select) {
        select.value = '';
      }
    };

    // // 新しい関数: カテゴリ変更時の処理
    const handleCategoryChange = async (event) => {
      const category = event.target.value;
      setSelectedCategory(category);
      
      // カテゴリが選択されていない場合は何もしない
      if (!category) return;
  
      try {
        const response = await api.get(`/first_id_by_category/${category}`);
        if (response.data && response.data.Id) {
          setCurrentId(response.data.Id);
          await fetchTextById(response.data.Id);
        } else {
          console.error('Invalid response from server');
          setErrorMessage('Failed to fetch category data. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching first ID by category:', error);
        setErrorMessage('Failed to fetch category data. Please try again.');
      }
  
      // カテゴリ選択後に選択ボックスをリセット
      resetCategorySelect();
    };

    const fetchTextById = useCallback(async (id) => {
      setIsLoading(true);
      try {
        const response = await api.get(`/text_by_id/${id}`);
        setTextData(response.data);
        resetRecordingStatus();
        setIsLastId(false);  // 新しいテキストが取得できた場合、最後のIDではない
      } catch (error) {
        console.error('Error fetching text:', error);
        if (error.response && error.response.status === 404) {
          // IDが見つからない場合、次の利用可能なテキストを取得
          await fetchNextAvailableText(id);
        } else {
          console.error('Unexpected error:', error.message);
          setIsLastId(true);  // エラーが発生した場合、最後のIDとして扱う
        }
      } finally {
        setIsLoading(false);
      }
    }, []);
  
  useEffect(() => {
    fetchTextById(currentId);
  }, [currentId, fetchTextById]);

  const fetchNextAvailableText = async (currentId) => {
    try {
      const response = await api.get(`${API_URL}/next_available_text/${currentId}`);
      if (response.data && response.data.Id !== currentId) {
        setTextData(response.data);
        setCurrentId(response.data.Id);
        setIsLastId(false);
      } else {
        // 次のテキストが見つからない場合（現在のIDが最後の場合）
        setIsLastId(true);
      }
    } catch (error) {
      console.error('Error fetching next available text:', error);
      // エラーが発生した場合も最後のIDとして扱う
      setIsLastId(true);
    }
  };

  const calculateIconPosition = () => {
    if (boxRef.current) {
      const svgElement = boxRef.current.querySelector('svg');
      if (svgElement) {
        const svgRect = svgElement.getBoundingClientRect();
        const iconTopPosition = window.scrollY + svgRect.top + (svgRect.height / 2);
        const iconLeftPosition = window.scrollX + svgRect.right + 16;
        setIconPosition({ left: iconLeftPosition + 'px', top: iconTopPosition + 'px' });
      }
    }
  };

  const handlePreviousClick = () => {
    setCurrentId(prevId => Math.max(1, prevId - 1));
    resetRecordingStatus();
  };

  const handleNextClick = () => {
    if (!isLastId) {
      setCurrentId(prevId => prevId + 1);
      resetRecordingStatus();
    } else {
      // 最後のIDの場合、最初のIDに戻る
      setCurrentId(1);
      setIsLastId(false);
      resetRecordingStatus();
    }
  };

  const handleAccordionChange = (event, isExpanded) => {
    // クリックがAccordionSummaryで発生した場合のみ展開/折りたたみを行う
    if (event.target.closest('.MuiAccordionSummary-root')) {
      setExpanded(isExpanded);
    }
  };
  
    // iOSの判定を改善
    const isIOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent) && !window.MSStream;
    };

// iOSに対応した改善版音声録音コード
const startRecording = async () => {
  logEvent('User Interaction', 'Record Button Click');
  setErrorMessage(null);
  if (recordingStatus === 'complete' || recordingStatus === 'idle') {
    resetRecordingStatus();
  }

  // iOSのSafariでの自動再生ポリシーに対応
  if (isIOS()) {
    await unlockAudioContext();
  }

  try {
    // 権限の確認と要求
    const permission = await navigator.permissions.query({ name: 'microphone' });
    if (permission.state === 'denied') {
      throw new Error("マイクの使用が許可されていません。ブラウザの設定を確認してください。");
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    
    // MIMEタイプのサポートを確認
    // MIMEタイプの設定
    let mimeType = 'audio/webm';
    if (isIOS()) {
      mimeType = 'audio/mp4';
    } else if (!MediaRecorder.isTypeSupported(mimeType)) {
      mimeType = 'audio/mp4';
    }
    
    if (!MediaRecorder.isTypeSupported(mimeType)) {
      //console.error(`MIMEタイプ ${mimeType} もサポートされていません。録音を中止します。`);
      throw new Error("お使いのブラウザはサポートされているオーディオ形式がありません。");
    }

    const recorder = new MediaRecorder(stream, { mimeType: mimeType });
    chunks.current = [];
    recorder.ondataavailable = (e) => {
      if (e.data.size > 0) {
        chunks.current.push(e.data);
      }
    };
    recorder.onstop = async () => {
      const blob = new Blob(chunks.current, { type: mimeType });
      await handleUpload(blob);
    };
    recorder.onerror = (event) => {
      //console.error('Recorder error:', event.error);
      setErrorMessage("録音中にエラーが発生しました。");
      setRecordingStatus('error');
    };

    // iOSのメモリ制限に対応するため、1秒ごとにデータを取得
    recorder.start(1000);

    setMediaRecorder(recorder);
    setIsRecording(true);
    setRecordingStatus('recording');
    setCountdown(5);  // カウントダウンをリセット

    // バックグラウンド移動の検出
    document.addEventListener('visibilitychange', handleVisibilityChange);

  } catch (error) {
    //console.error('Error starting recording:', error);
    setRecordingStatus('error');
    setErrorMessage(error.message || "録音の開始に失敗しました。もう一度お試しください。");
    setIsErrorDialogOpen(true);
  }
};


// iOSの自動再生ポリシーに対応
const unlockAudioContext = async () => {
  if (isIOS()) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    await audioContext.resume();
    const source = audioContext.createBufferSource();
    source.buffer = audioContext.createBuffer(1, 1, 22050);
    source.connect(audioContext.destination);
    source.start(0);
  }
};

// バックグラウンド移動の処理
const handleVisibilityChange = () => {
  if (document.hidden && isRecording) {
    // バックグラウンドに移動した場合の処理
    mediaRecorder.pause();
    setErrorMessage("バックグラウンドに移動したため、録音が一時停止されました。");
  } else if (!document.hidden && isRecording) {
    // フォアグラウンドに戻った場合の処理
    mediaRecorder.resume();
    setErrorMessage(null);
  }
};

// コンポーネントのクリーンアップ
useEffect(() => {
  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, []);

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      setIsRecording(false);
      setRecordingStatus('processing');
    }
  };

  const handleRecordingComplete = async () => {
    try {
      const blob = new Blob(chunks.current, { type: 'audio/webm' });
      setAudioBlob(blob);
      await handleUpload(blob);
    } catch (error) {
      //console.error('Error processing audio:', error);
      setRecordingStatus('error');
    }
  };

  const pollForResult = async (taskId) => {
    //console.log("Polling for result, task ID:", taskId);
    try {
      const response = await api.get(`/result/${taskId}`);
      //console.log("Poll response:", response.data);
      if (response.data.state === 'SUCCESS') {
        //console.log("Task completed successfully");
        if (response.data.result.error === 'zero_f0_detected') {
          setErrorMessage("Analysis Error. Please try again.");
          setRecordingStatus('idle');
          setIsErrorDialogOpen(true);
        } else {
          handleResult(response.data.result);
        }
      } else if (response.data.state === 'PENDING' || response.data.state === 'STARTED') {
        //console.log(`Task still ${response.data.state.toLowerCase()}, polling again in 1 second`);
        setTimeout(() => pollForResult(taskId), 1000);
      } else if (response.data.state === 'FAILURE') {
        //console.error("Task failed:", response.data.error);
        setRecordingStatus('error');
        setErrorMessage("An error occurred. Please try again.");
      } else {
        //console.error("Unexpected task state:", response.data.state);
        setRecordingStatus('error');
        setErrorMessage("An unexpected error occurred. Please try again.");
      }
    } catch (error) {
      //console.error('Error polling for result:', error);
      setRecordingStatus('error');
      setErrorMessage("Network error. Please check your connection and try again.");
    }
  };

  const validateAudioFile = (file) => {
    return new Promise((resolve, reject) => {
      // ファイルタイプのチェックのみを行い、変更は行わない
      if (file.type === 'audio/webm' || file.type === 'audio/mp4') {
        resolve(file);
      } else {
        reject(new Error("Invalid audio file format"));
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        const arr = new Uint8Array(e.target.result).subarray(0, 12);
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        //console.log("File header:", header);
        
        // WebMファイルのマジックナンバーをチェック
        if (header.startsWith("1a45dfa3")) {
          //console.log("Valid WebM file detected");
          resolve(true);
        // MP4ファイルのマジックナンバーをチェック（より広範囲に対応）
        } else if (header.includes("66747970") || header.includes("6d703432") || header.includes("69736f6d") || header.startsWith("000000")) {
          //console.log("Valid MP4 file detected");
          resolve(true);
        } else {
          //console.error("Invalid file header");
          reject(new Error("Invalid audio file format"));
        }
      };
      reader.onerror = () => {
        //console.error("File reading failed");
        reject(new Error("File reading failed"));
      };
      reader.readAsArrayBuffer(file);
    });
  };
  
  // ファイルアップロード処理の修正
  const handleUpload = async (blob) => {
    try {
      await validateAudioFile(blob);
      const formData = new FormData();
      const fileExtension = blob.type.includes('mp4') ? 'mp4' : 'webm';      
      formData.append('file', blob, `audio.${fileExtension}`);
      formData.append('text', new Blob([textData.Text], { type: 'text/plain' }), 'text.txt');
      formData.append('kanji', textData.Kanji);
      formData.append('device_id', getSessionId());

      const response = await api.post('/process', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
  
      if (response.data && response.data.task_id) {
        pollForResult(response.data.task_id);
      } else {
        throw new Error('No task ID received from server');
      }
    } catch (error) {
      //console.error("File upload failed:", error);
      setRecordingStatus('error');
      setErrorMessage(error.message || "Invalid audio file. Please try recording again.");
      setIsErrorDialogOpen(true);
    }
  };

  const sendAudioToServer = async (blob) => {
    const formData = new FormData();
    formData.append('file', blob, 'audio.webm');
    formData.append('text', new Blob([textData.Text], { type: 'text/plain' }), 'text.txt');
    formData.append('kanji', textData.Kanji);
    formData.append('device_id', getSessionId());

    try {
      const response = await api.post('/process', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (response.data && response.data.task_id) {
        pollForResult(response.data.task_id);
      }
    } catch (error) {
      //console.error('Error sending audio to server:', error);
      setRecordingStatus('error');
      setErrorMessage("Failed to send audio to server. Please try again.");
      setIsErrorDialogOpen(true);
    }
  };

  const handleCloseErrorDialog = () => {
    setIsErrorDialogOpen(false);
    setErrorMessage(null);
    setRecordingStatus('idle');
  };

  const handleResult = (result) => {
    setRecordingStatus('complete');
    setUserAccent(result.AnalyzedAccent);
    setResultData({
      userAccent: result.AnalyzedAccent,
      isCorrect: result.AnalyzedAccent === textData.Accent
    });
  };

  const handleListen = async () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      setIsSynthesizing(false);
    } else {
      setIsSynthesizing(true);  // 合成開始時にtrueに設定
      try {
        const response = await api.post('/synthesize', {
          text: textData.Text,
          kanji: textData.Kanji
        }, { responseType: 'blob' });
        
        const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        
        audioRef.current.src = audioUrl;
        await audioRef.current.play();  // play()の完了を待つ
        setIsPlaying(true);
        
        audioRef.current.onended = () => {
          setIsPlaying(false);
          setIsSynthesizing(false);
        };
      } catch (error) {
        console.error('Error synthesizing speech:', error);
        if (error.response) {
          console.error('Error status:', error.response.status);
          if (error.response.data instanceof Blob) {
            const reader = new FileReader();
            reader.onload = function() {
              const errorMessage = JSON.parse(this.result);
              console.error('Error data:', errorMessage);
              setErrorMessage(`Failed to synthesize speech: ${errorMessage.error || 'Unknown error'}`);
            };
            reader.readAsText(error.response.data);
          } else {
            console.error('Error data:', error.response.data);
            setErrorMessage(`Failed to synthesize speech: ${error.response.data.error || 'Unknown error'}`);
          }
        } else if (error.request) {
          console.error('No response received:', error.request);
          setErrorMessage('Failed to synthesize speech: No response from server');
        } else {
          console.error('Error message:', error.message);
          setErrorMessage(`Failed to synthesize speech: ${error.message}`);
        }
      } finally {
        setIsSynthesizing(false);  // 処理完了時（成功もエラーも）にfalseに設定
      }
    }
  };
  /*
  const HowItWorksBlock = () => (
    <Accordion 
      expanded={expanded} 
      onChange={handleAccordionChange}
      sx={{ mt: 2, width: '100%' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="how-it-works-content"
        id="how-it-works-header"
      >
        <Typography align="center" sx={{ width: '100%' }}>How It Works</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ maxHeight: '200px', overflowY: 'auto' }}>
        <Typography paragraph align="center">
          Japanese accents are expressed through pitch. If the pitch accent is different, it can not only give the impression of being non-native but also change the meaning. For example, the same sound "はし" can mean:
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              mb: 2, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'  // 高さを100%に設定
            }}>
              <AccentVisualizer
                text="はし"
                accent="LH"
                isVisualizationEnabled={true}
              />
              <Typography variant="caption" sx={{ mt: 1 }}>はし (橋 / Bridge)</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ 
              mb: 2, 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%'  // 高さを100%に設定
            }}>
              <AccentVisualizer
                text="はし"
                accent="HL"
                isVisualizationEnabled={true}
              />
              <Typography variant="caption" sx={{ mt: 1 }}>はし (箸 / Chopsticks)</Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography align="center">
          If a horizontal line is displayed above the character, read it with a high pitch; if a horizontal line is displayed below the character, read it with a low pitch. This will sound natural to Japanese natives and make communication smoother. In this app, if you read the displayed characters according to the displayed accents, the AI will analyze and determine if the accent is correct. Don't be embarrassed to try multiple times, practice repeatedly to master the correct pronunciation.
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
  */

  const handleTermsClick = () => {
    setIsTermsDialogOpen(true);
  };

  const handleCloseTermsDialog = () => {
    setIsTermsDialogOpen(false);
  };

  const handlePrivacyClick = () => {
    setIsPrivacyDialogOpen(true);
  };

  const handleClosePrivacyDialog = () => {
    setIsPrivacyDialogOpen(false);
  };

  const handleSearch = useCallback(async (query) => {
    setSearchQuery(query);
    setSearchError(null);
  
    if (query.trim() === '') {
      setSearchResults([]);
      setIsSearching(false);
      return;
    }
  
    setIsSearching(true);
  
    try {
      const response = await api.get(`/search?query=${encodeURIComponent(query)}`);
      
      if (response.status === 200) {
        setSearchResults(response.data);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Search error:', error);
      setSearchError('An error occurred while searching. Please try again.');
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  }, []);

  const handleSelectSearchResult = useCallback((result) => {
    setTextData(result);
    setCurrentId(result.Id);
    
    // 検索結果をクリア
    setSearchResults([]);
    setSearchQuery('');
    
    // 必要に応じて他の状態をリセット
    setUserAccent(null);
    setResultData(null);
    
    // テキストデータの取得（必要な場合）
    fetchTextById(result.Id);
  }, []);
  
  return (
    <>
      <AppBar 
        position="static" 
        elevation={0}
        sx={{ 
          bgcolor: '#19e64c',
          height: '48px',
        }}
      >
        <Toolbar variant="dense">
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ 
              flexGrow: 1, 
              color: 'white',
              fontSize: '1.1rem',
            }}
          >
            ShaberÖ
          </Typography>
        </Toolbar>
      </AppBar>
  
      <Box sx={{ 
        p: 3, 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        minHeight: 'calc(100vh - 128px)',
      }}>
        <SearchBox 
          value={searchQuery}
          onChange={handleSearch}
          isLoading={isSearching}
        />

    <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
      <InputLabel 
        id="category-select-label" 
        shrink 
        sx={{
          backgroundColor: 'transparent',
          padding: '0 4px',
          marginTop: '-6px',
        }}
      >
        Category
      </InputLabel>
      <Select
        labelId="category-select-label"
        id="category-select"
        defaultValue=""  // valueをdefaultValueに変更
        label="Category"
        onChange={handleCategoryChange}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em>Select the category to start</em>;
          }
          return selected;
        }}
        sx={{
          '& .MuiSelect-select': {
            paddingTop: '16px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#19e64c',
          },
        }}
      >
        <MenuItem value="" disabled>
          <em>Select the category to start</em>
        </MenuItem>
        {categories.map((category) => (
          <MenuItem key={category} value={category}>{category}</MenuItem>
        ))}
      </Select>
    </FormControl>
        {searchQuery.trim() !== '' && (
          <>
            {isSearching && <CircularProgress />}
            {!isSearching && searchResults.length > 0 && (
              <SearchResults 
                results={searchResults}
                onSelect={handleSelectSearchResult}
              />
            )}
            {!isSearching && searchResults.length === 0 && (
              <Typography>No results found</Typography>
            )}
          </>
        )}
  
        <Typography variant="h5" gutterBottom align="center">
          Read out the Japanese word.
        </Typography>
        <Box sx={{ 
          mb: 2, 
          width: '100%', 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '150px',
          justifyContent: 'center',
        }} ref={boxRef}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            textData && (
              <>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1, mb: 1, textAlign: 'center' }}>
                  {textData.Kanji} / {textData.Meaning}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  justifyContent: 'center', 
                  width: '100%',
                  pl: { xs: 5, sm: 5, md: 5 },
                }}>
                  <AccentVisualizer
                    text={textData.Text}
                    accent={textData.Accent}
                    isVisualizationEnabled={accentVisualization}
                    userAccent={userAccent}
                  />
                  <Box sx={{ position: 'relative', ml: 1 }}>
                    <IconButton 
                      onClick={handleListen} 
                      aria-label="listen"
                      disabled={isSynthesizing}
                    >
                      <VolumeUpIcon fontSize="small" />
                    </IconButton>
                    {isSynthesizing && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ mt: 1, mb: 0, textAlign: 'center' }}>
                  {textData.Alphabet}
                </Typography>
              </>
            )
          )}
        </Box>
  
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ mb: 0, mt: 0, width: '100%' }}>
          <Typography>Accent Visualization</Typography>
          <Switch
            checked={accentVisualization}
            onChange={() => setAccentVisualization(!accentVisualization)}
          />
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          width: '100%', 
          mt: 2 
        }}>
          <IconButton 
            onClick={handlePreviousClick}
            disabled={isRecording || recordingStatus === 'processing' || currentId <= 1}
            sx={{ mr: 1 }}
          >
            <ArrowBackIcon />
          </IconButton>
          
          <Button
            variant="contained"
            onClick={startRecording}
            disabled={isRecording || recordingStatus === 'processing'}
            sx={{ 
              width: '60%',
              maxWidth: '200px',
            }}
          >
            {isRecording ? `Recording...` : 'Record'}
          </Button>
          
          <IconButton 
            onClick={handleNextClick}
            disabled={isRecording || recordingStatus === 'processing'}
            sx={{ ml: 1 }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
  
        <HowItWorksBlock />
  
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, position: 'relative' }}>
          {recordingStatus === 'processing' && <CircularProgress />}
  
          {isRecording && (
            <Typography 
              variant="h6" 
              sx={{ 
                mt:2,
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)',
                color: 'Gray'
              }}
            >
              {countdown}
            </Typography>
          )}
        </Box>
  
        {resultData && (
          <ResultDisplay
            textData={textData}
            userAccent={resultData.userAccent}
            isCorrect={resultData.isCorrect}
          />
        )}
      </Box>  
  
      <Box 
        component="footer" 
        sx={{ 
          py: 2,
          px: 2, 
          mt: 'auto', 
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <Link 
              href="#" 
              onClick={handleTermsClick} 
              sx={{ mr: 2, color: 'black', textDecoration: 'none' }}
            >
              Terms of Use
            </Link>
            <Link 
              href="#" 
              onClick={handlePrivacyClick} 
              sx={{ mr: 2, color: 'black', textDecoration: 'none' }}
            >
              Privacy Policy
            </Link>
            <Link 
              href="https://tayori.com/form/9c1e3414dc5746898896dfd2ccee455041ab4d79" 
              target="_blank" 
              rel="noopener noreferrer"
              sx={{ color: 'black', textDecoration: 'none' }}
            >
              Contact
            </Link>
          </Box>
  
          <Typography variant="caption" sx={{ color: 'black' }}>
            © 2024 ShaberÖ
          </Typography>
        </Container>
      </Box>
  
      <Dialog
        open={isErrorDialogOpen}
        onClose={handleCloseErrorDialog}
        aria-labelledby="error-dialog-title"
        aria-describedby="error-dialog-description"
      >
        <DialogTitle id="error-dialog-title">{"Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorDialog} color="primary" autoFocus>
            Try Again
          </Button>
        </DialogActions>
      </Dialog>
  
      <Dialog
        open={isTermsDialogOpen}
        onClose={handleCloseTermsDialog}
        aria-labelledby="terms-dialog-title"
        aria-describedby="terms-dialog-description"
      >
        <DialogTitle id="terms-dialog-title">{"Terms of Use"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="terms-dialog-description">
            <TermsOfUse />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTermsDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      
      <Dialog
        open={isPrivacyDialogOpen}
        onClose={handleClosePrivacyDialog}
        aria-labelledby="privacy-dialog-title"
        aria-describedby="privacy-dialog-description"
      >
        <DialogTitle id="privacy-dialog-title">{"Privacy Policy"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="privacy-dialog-description">
            <PrivacyPolicy />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrivacyDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Initial;