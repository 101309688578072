import React from 'react';
import { Typography, Box } from '@mui/material';

const TermsOfUse = () => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Effective Date: Jul. 31th 2024
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        Welcome to Shabero!
      </Typography>
      
      <Typography variant="body1" paragraph>
        These Terms of Service ("Terms") govern your use of the Shabero mobile application ("App"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the App.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        1. Acceptance of Terms
      </Typography>
      
      <Typography variant="body1" paragraph>
        By accessing and using Shabero, you acknowledge that you have read, understood, and agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of these Terms, you are prohibited from using the App.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        2. Description of Service
      </Typography>
      
      <Typography variant="body1" paragraph>
        Shabero is an application that processes spoken voice data sent to our servers. The primary function of the App is to analyze the audio data you provide to improve language learning and other related services.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        3. User Responsibilities
      </Typography>
      
      <Typography variant="body1" paragraph>
        - You agree to use the App only for lawful purposes and in a way that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the App.
      </Typography>
      <Typography variant="body1" paragraph>
        - You must not misuse the App by knowingly introducing viruses, trojans, worms, or other material that is malicious or technologically harmful.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        4. Data Collection and Usage
      </Typography>
      
      <Typography variant="body1" paragraph>
        - By using the App, you consent to the collection and processing of your spoken voice data. This data is sent to our servers for analysis.
      </Typography>
      <Typography variant="body1" paragraph>
        - The voice data collected will be used to enhance and improve the App's services and functionalities.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        5. Intellectual Property Rights
      </Typography>
      
      <Typography variant="body1" paragraph>
        - All intellectual property rights in the App and its content are owned by or licensed to Shabero, subject to the terms and conditions of this agreement.
      </Typography>
      <Typography variant="body1" paragraph>
        - You may not modify, reproduce, or distribute any part of the App or its content without our prior written permission.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        6. Termination
      </Typography>
      
      <Typography variant="body1" paragraph>
        - We reserve the right to terminate or suspend your access to the App at our sole discretion, without notice or liability, for any reason, including if you breach these Terms.
      </Typography>
      <Typography variant="body1" paragraph>
        - Upon termination, your right to use the App will immediately cease.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        7. Limitation of Liability
      </Typography>
      
      <Typography variant="body1" paragraph>
        - The App is provided "as is" and "as available" without any warranties of any kind, either express or implied.
      </Typography>
      <Typography variant="body1" paragraph>
        - In no event shall Shabero, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the App.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        8. Changes to Terms
      </Typography>
      
      <Typography variant="body1" paragraph>
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the App after such changes have been made will constitute your acceptance of the new Terms.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        9. Governing Law
      </Typography>
      
      <Typography variant="body1" paragraph>
        These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
      </Typography>
      
      <Typography variant="h6" gutterBottom>
        10. Contact Information
      </Typography>
      
      <Typography variant="body1" paragraph>
        If you have any questions about these Terms, please contact us at https://tayori.com/form/9c1e3414dc5746898896dfd2ccee455041ab4d79
      </Typography>
      
      <Typography variant="body1" paragraph>
        Thank you for using Shabero!
      </Typography>
    </Box>
  );
};

export default TermsOfUse;
